.content_user_activity {
  .nav.nav-tabs {
    margin-bottom: 0 !important;
    .nav-item {
      color: #666;
      &.active {
        color: #333;
        font-family: "is_medium";
      }
    }
  }
  .itemcatred {
    background: var(--primary_color);
    color: #efefef;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
  .itemcatgreen {
    background: rgb(2, 172, 39);
    color: #efefef;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
  .righttext{
    text-align: right;
  }
  .centertext{
    text-align: center;
  }
  .tab-content {
    border: 1px solid;
    border-color: #ffffff #dee2e6 #dee2e6 #dee2e6;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0.5rem 0.5rem;
    background: #ffffff;
  }
  .content_history_activity,
  .content_services_profile {
    padding: 2rem 1.5rem;
  }
  .table{
    font-size: .85rem;
  }
}
