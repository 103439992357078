@import "../../assets/theme/vars";

.wrapper_course {
  .content_ttls {
    background: #c0e9ae;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem;
    margin-bottom: 2rem !important;
    .box_img_surveys {
      text-align: center;
      min-width: 180px;
      img {
        width: 100%;
      }
    }
    .minw {
      max-width: 500px;
    }
  }
  .content_card_audience {
    padding: 1.25rem 2rem;
    .box_list_audience {
      width: 100%;
      .item_audience {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.3rem;
        border-radius: 5px;
        margin: 0.5rem 0;
        font-size: 0.95rem;
        &:hover {
          background: $bg_grey;
          color: var(--primary_color);
        }
        .box_info_audience {
          padding-right: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
            word-break: break-word;

          .box_icon {
            min-width: 65px;
            font-size: 2.5rem;
            text-align: center;
          }
        }
      }
    }
  }
  .content_info_course{
    .card-title{
      min-height: 48px;
    }
    .p_paragraph{
      min-height: 50px;
      color: #888888;
      line-height: 1.2;
      font-size: .90rem;
      margin-bottom: .25rem;
    }
    .c_primary{
      font-size: .90rem;
      cursor: pointer;
    }
  }
  .box_img_stories.left_image {
    height: 230px;
    .boxImage{
      border-radius: .5rem;
    }
  }
  .nav-tabs{
    border-bottom: 1px solid #dedede;
  }
  .tab-content{
    margin-top: 2rem;
  }
}
