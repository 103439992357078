@import "../../assets/theme/vars";

.wrapper_our_company {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .nav-tabs {
    border-bottom: 1px solid hsl(0, 0%, 40%);
    justify-content: space-around;
    .nav-item {
      margin: 0;
      width: 100%;
      .nav-link {
        min-width: auto !important;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        background: transparent;
        border: 0;
        &.active {
          font-family: "is_medium";
          color: #333333;
          border-bottom: 5px solid var(--primary_color);
        }
      }
    }
    .tab-content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .sec_list_post {
    background: #ededed;
    width: 100%;
    max-width: 290px;
    height: calc(100vh - 4rem);
    overflow: auto;
    @include scrollbar_vertical;
    .box_ttl_opt {
      position: relative;
      .btn_ellipsis {
        background: #dddddd !important;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
        border-radius: 50%;
        background: $bg_grey;
        cursor: pointer;
        color: #000000;
        position: absolute;
        top: 5px;
        right: 0;
      }
    }
    .divtop {
      padding: 0 1.25rem;
      padding-top: 2rem;
    }
    .box_ttls_sec {
      margin: 0 1.25rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding: 1.25rem 0.8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $white_text;
      &.article {
        // background: #4282f5;
        //background: #00b2e3;
        background: var(--secondary_color);
        .is_mes {
          text-transform: capitalize;
        }
      }
      &.gallery {
        background: #e76e0a;
        .ico_box_sec {
          color: #e76e0a;
        }
      }
      .ico_box_sec {
        background: $white_text;
        color: var(--secondary_color);
        min-width: 50px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 0.5rem;
        text-transform: uppercase;
        font-family: "is_bold";
        font-size: 2rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
    .content_list_post {
      hr {
        margin: 0.5rem 0;
      }
      .item_list_post {
        background: $white_text !important;
        padding: 1.25rem 1rem;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #dedede;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        & > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &:hover {
          &.is_read {
            border-radius: 0.3rem 0.3rem 0 0 !important;
            color: $black;
            opacity: 1;
          }
        }
        &.is_read {
          background: transparent !important;
          color: #666666;
          box-shadow: none;
          border-radius: 0 !important;
          opacity: 0.9;
        }
        .box_avatar_user {
          min-width: 60px;
          .avatar {
            height: 50px;
            width: 50px;
          }
        }
        .date_post {
          font-size: 0.75rem;
          color: #999999;
        }
        .views_post {
          position: absolute;
          top: 0.35rem;
          right: 1rem;
          color: #999999;
          font-size: 0.7rem;
        }
      }
    }
  }

  .sec_post {
    width: 100%;
    height: calc(100vh - 4rem);
    position: relative;
    .back_ico {
      display: none;
      background: $white_text;
      padding: 0.25rem 0.35rem;
      border-radius: 5px;
      position: absolute;
      top: 10px;
      left: 25px;
      color: #666666;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    }
    .content_post {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .content_info_post {
        padding: 2rem;
        width: 100%;
        overflow: auto;
        height: 100%;
        @include scrollbar_vertical;
        .info_post {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0.5rem 0 2rem;
          .box_avatar_user {
            min-width: 75px;
            .avatar {
              height: 65px;
              width: 65px;
            }
          }
        }
      }
      .sec_controls_post {
        background: $white_text;
        width: 100%;
        height: auto;
        box-shadow: 2px -2px 9px rgba(0, 0, 0, 0.1);
        .content_options_post {
          .box_files_post {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            padding: 0.5rem 0.75rem;
            font-size: 0.9rem;
            border-bottom: 1px solid #dfdfdf;
            .likes {
              min-width: 50px;
              width: 100%;
              .like_heart {
                font-size: 1.2rem;
                margin-right: 0.25rem;
                color: #da2222;
              }
            }
            .link_comments {
              cursor: pointer;
              min-width: 125px;
              padding: 0.35rem 0.5rem;
              border-radius: 0.25rem;
              text-align: center;
              &:hover {
                background: #efefef;
              }
            }
            .read {
              min-width: 75px;
              text-align: center;
            }
            .files {
              min-width: 100px;
              .item_file {
                margin: 0;
              }
            }
          }
          .box_actions_post {
            font-size: 0.9rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.75rem 0.5rem;
            border-bottom: 1px solid #dfdfdf;
            .box_btns_post {
              display: flex;
              align-items: center;
              gap: 5px;
              .btn_action {
                padding: 0.25rem 0;
                border-radius: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                
                & > i {
                  margin-right: 0.2rem;
                  font-size: 1rem;
                  &.far {
                    color: $black;
                  }
                  &.fas {
                    color: #da2222;
                  }
                }
              }
              &:hover {
              text-decoration: underline;
              //text-shadow: 0px 0px 5px var(--primary_color);
            }
            }
            .btn_comment_box {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
            .box_tag_read {
              .tag_read {
                cursor: pointer;
                .fas {
                  font-size: 0.75rem;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .box_comments_box {
            display: none;
            flex-direction: column;
            justify-content: space-between;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &.is-show {
              display: flex;
            }
            .head_list_comment {
              display: flex;
              align-items: center;
              padding: 0.65rem 1rem;
              padding-top: 1rem;
              border-bottom: 1px solid #dedede;
              .btngoback {
                width: 23px;
                height: 23px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.35rem;
                cursor: pointer;
                &:hover {
                  background: #eeeeee;
                }
              }
              h6 {
                margin-bottom: 0;
                font-weight: 600;
              }
            }
            .contain_comments {
              height: 100%;
              overflow: auto;
              padding: 1rem 2rem;
              .wrapper__items_comments {
                margin-bottom: 1rem;
                .items_comment_mnj {
                  display: flex;
                  gap: 12px;
                  .wrapper__bg_flags {
                    right: 0px;
                    top: -4px;
                    margin-top: 0.35rem;
                    .bgflag {
                      width: 41px;
                      min-width: 41px;
                      height: 41px;
                    }
                    .contain_avatar {
                      top: 3px;
                      left: 3px;
                      .box_avatar {
                        width: 35px;
                        min-width: 35px;
                        height: 35px;
                      }
                    }
                  }
                  .drawer_comment_mnj {
                    width: 100%;
                    .comment_mjs {
                      border: 1px solid #dedede;
                      padding: 0.6rem 0.75rem;
                      border-radius: 0.75rem;
                      line-height: 1.25;
                      background: #f6f6f6;
                      font-size: 0.9rem;
                      .name_author {
                        font-weight: 600;
                        font-size: 0.85rem;
                      }
                      p {
                        margin-bottom: 0;
                      }
                    }

                    .options_comment_mnj {
                      font-size: 0.85rem;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin: 0 0.75rem;
                      color: #aaaaaa;
                      margin-top: 0.25rem;
                      .btn_answer {
                        color: #333333;
                        cursor: pointer;
                        &:hover {
                          color: var(--primary_color);
                          text-decoration: underline;
                        }
                      }
                      .spandate {
                        text-align: right;
                        font-size: 0.75rem;
                      }
                    }
                  }
                  .btnx-group {
                    .dropdown-toggle {
                      background: #f6f6f6;
                      cursor: pointer;
                      color: #999;
                      display: flex;
                      width: 23px;
                      height: 23px;
                      border-radius: 50%;
                      padding-left: 0.6rem;
                      display: flex;
                      justify-items: center;
                      align-items: center;
                      &::after {
                        display: none;
                      }
                      &:hover {
                        background: var(--primary_color);
                        color: #fff;
                      }
                    }
                  }
                }
                .answer__in_comment {
                  margin-top: 0.5rem;
                  margin-left: 45px;
                  margin-right: 0;
                  padding: 0;
                  
                  

                  // .items_comment_mnj{
                    position: relative;
                    border-left: 2px dashed #dedede;
                    padding-left: 15px;
                    &::before {
                      content: "";
                      background: #dedede;
                      position: absolute;
                      width: 7px;
                      height: 7px;
                      top: 0;
                      left: -4.5px;
                      border-radius: 50%;
                    }
                    &::after {
                      content: "";
                      background: #dedede;
                      position: absolute;
                      width: 7px;
                      height: 7px;
                      bottom: 0;
                      left: -4.5px;
                      border-radius: 50%;
                    }
                    &:empty {
                      border: 0;
                      &::before,
                      &::after {
                        display: none;
                      }
                    }
                  // }

                  .box_for_comments {
                    display: none;
                    &.is-show {
                      display: block;
                    }
                    .contain_write_comments {
                      padding: 0.5rem;
                      border: 0;
                      border-radius: 5px;
                      margin-bottom: .75rem;
                      .public-DraftStyleDefault-block {
                        background: #fefefe;
                      }
                    }
                  }
                  .wrapper__bg_flags {
                    right: 0px;
                    top: -4px;
                    .bgflag {
                      width: 30px;
                      min-width: 30px;
                      height: 30px;
                    }
                    .contain_avatar {
                      .box_avatar {
                        width: 24px;
                        min-width: 24px;
                        height: 24px;
                      }
                    }
                  }
                }
              }
            }
            .contain_write_comments {
              background: #f6f6f6;
              padding: 1rem 1.8rem 2rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 1px solid #dedede;
              .wrapper__bg_flags {
                right: 0px;
                top: -4px;
                .bgflag {
                  width: 44px;
                  min-width: 44px;
                  height: 44px;
                }
                .contain_avatar {
                  top: 3px;
                  left: 3px;
                  .box_avatar {
                    width: 38px;
                    min-width: 38px;
                    height: 38px;
                  }
                }
              }
              .box--comments {
                position: relative;
                margin: 0 0.75rem;
                width: 100%;
                .toolbar-box-comments {
                  position: absolute;
                  right: -4px;
                  top: 0px;
                  z-index: 9;
                  background: transparent;
                  border: 0;
                  .rdw-option-wrapper {
                    background: transparent;
                    border: 0;
                    &:hover {
                      box-shadow: none;
                    }
                  }
                  .rdw-emoji-modal {
                    top: auto;
                    bottom: 25px;
                    left: auto;
                    right: 0;
                    border-radius: 10px;
                  }
                }
                .editor-box-comments {
                  background: #fff;
                  border: 1px solid #dedede;
                  border-radius: 25px;
                  .public-DraftStyleDefault-block {
                    margin: 0;
                    padding: 0.5rem 1rem;
                    padding-right: 2rem;
                    max-height: 30px;
                    line-height: 1.2;
                  }
                }
              }
              .btn_send {
                background: var(--primary_color);
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .ReactGridGallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .ReactGridGallery_tile {
        width: calc(25% - 5px);
        margin: 5px !important;
        text-align: center;
        .ReactGridGallery_tile-viewport {
          height: 115px !important;
          img {
            height: 115px !important;
          }
        }
      }
    }
  }

  $optionDefaultColours: #ffc004, #c00000, #70ac47, #5d9cec, #70459f, #ff5735;

  .sec_aside_our_company {
    background: $white_text;
    border-left: 1px solid #dddddd;
    padding: 2rem 1.25rem;
    max-width: 250px;
    width: 100%;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .closex {
      display: none;
      font-size: 1.25rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .sec_more_post {
      .box_items_years {
        @include scrollbar_vertical;
        padding: 1rem 0;
        height: calc(100vh - 10em);
        overflow: auto;
      }
      .item_more_post {
        .box_collapse {
          .box_ul_month {
            li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 0.5rem;
              cursor: pointer;
              color: #888888;
              @for $i from 1 through length($optionDefaultColours) {
                &:nth-child(#{$i}) {
                  --defaultBackground: #{nth($optionDefaultColours, $i)};
                }
                &:hover {
                  color: #222222;
                }
              }
              .dots {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                border-radius: 50%;
                font-family: "is_medium";
                color: $white_text;
                margin-right: 0.5rem;
                background: var(
                  --optionBackground,
                  var(--defaultBackground, #ed5565)
                );
              }
              .name_mes {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
