@import "../../assets/theme/vars";

.wrapper_header {
  background: #ffffff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  width: 100%;
  padding: 0 1rem;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  .box_brand {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 195px;
    min-width: 195px;
    .btn_menu {
      display: none;
      margin-right: 0.75rem;
      max-width: 26px;
      width: 100%;
      height: 20px;
      position: relative;
      top: 0;
      z-index: 2;
      cursor: pointer;
      &:hover {
        color: royalblue;
      }
      & span {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        display: block;
        position: absolute;
        background: var(--primary_color);
        transition: all 0.35s ease;
        transform-origin: 0px 100%;
        &:nth-child(2) {
          top: calc(50% - 2px);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
      &.visible_menu {
        right: -5px;
        span:nth-child(1) {
          transform: rotate(45deg) translate(-3px, 2px);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:nth-child(3) {
          transform: rotate(-45deg) translate(-2px, 1px);
        }
      }
    }
    img {
      max-height: 50px;
    }
  }
  .content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_search_input {
      .input-group {
        .input-group-prepend {
          .input-group-text {
            background: $bg_grey;
            border-radius: 30px 0 0 30px;
          }
        }
        .form-control {
          border-radius: 0 30px 30px 0;
          height: calc(1.5em + 0.5rem + 2px);
          background: $bg_grey;
        }
      }
    }
    .box_options {
      padding-right: 5px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .ico_share{
        font-size: 20px;
        margin-right: .75rem !important;
      }
      .wrapper__bg_flags {
        right: 0px;
        top: -4px;
        .bgflag {
          width: 41px;
          min-width: 41px;
          height: 41px;
        }
        .contain_avatar {
          top: 3px;
          left: 3px;
          .box_avatar {
            width: 35px;
            min-width: 35px;
            height: 35px;
          }
        }
      }
      .btn_share{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1rem;
        height: 100%;
        cursor: pointer;
        .icon_share{
          position: relative;
        }
        &:hover .icon_share {
          color: var(--primary_color);
        }
      }
      .popup{
        position: absolute;
        left: 50%;
      }
      button{
        outline: none;
        cursor: pointer;
        font-weight: 500;
        border-radius: 4px;
        border: 2px solid transparent;
        transition:  0.1s linear, border-color 0.1s linear, color 0.1s linear;
      }
      .view-modal{
        top: 50%;
        color: #7d2ae8;
        font-size: 18px;
        padding: 10px 25px;
        background: #fff;
        transform: translate(-50%, -50%);
      }
      .popup{
        background: #fff;
        padding: 25px;
        border-radius: 15px;
        top: -150%;
        max-width: 380px;
        width: 200%;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
        transform: translate(-50%, -50%) scale(1.2);
        transition: top 0s 0.2s ease-in-out,
                    opacity 0.2s 0s ease-in-out,
                    transform 0.2s 0s ease-in-out;
      }
      .popup.show{
        top: 50%;
        opacity: 1;
        pointer-events: auto;
        width: auto;
        transform:translate(-50%, -50%) scale(1);
        transition: top 0s 0s ease-in-out,
                    opacity 0.2s 0s ease-in-out,
                    transform 0.2s 0s ease-in-out;
      
      }
      .popup :is(header, .icons, .field){
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .popup header{
        padding-bottom: 15px;
        border-bottom: 1px solid #ebedf9;
      }
      header span{
        font-size: 21px;
        font-weight: 600;
      }
      header .close, .icons a{
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        transition: all 0.3s ease-in-out;
      }
      header .close{
        color: #878787;
        font-size: 17px;
        background: #f2f3fb;
        height: 33px;
        width: 33px;
        cursor: pointer;
      }
      header .close:hover{
        background: #ebedf9;
      }
      .popup .content{
        margin: 20px 0;
      }
      .popup .icons{
        margin: 15px 0 20px 0;
        margin-right: 35px;
      }
      .content p{
        font-size: 16px;
      }
      .content .icons a{
        height: 50px;
        width: 50px;
        font-size: 20px;
        text-decoration: none;
        border: 1px solid transparent;
        align-items: center;
      }
      .icons a i{
        transition: transform 0.3s ease-in-out;
      }
      .icons a:nth-child(1){
        color: #1877F2;
        border-color: #b7d4fb;
      }
      .icons a:nth-child(1):hover{
        background: #1877F2;
      }
      .icons a:nth-child(2){
        color: #46C1F6;
        border-color: #b6e7fc;
      }
      .icons a:nth-child(2):hover{
        background: #46C1F6;
      }
      .icons a:nth-child(3){
        color: #e1306c;
        border-color: #f5bccf;
      }
      .icons a:nth-child(3):hover{
        background: #e1306c;
      }
      .icons a:nth-child(4){
        color: #25D366;
        border-color: #bef4d2;
      }
      .icons a:nth-child(4):hover{
        background: #25D366;
      }
      .icons a:nth-child(5){
        color: #0088cc;
        border-color: #b3e6ff;
      }
      .icons a:nth-child(5):hover{
        background: #0088cc;
      }
      .icons a:hover{
        color: #fff;
        border-color: transparent;
      }
      .icons a:hover i{
        transform: scale(1.2);
      }
      .content .field{
        margin: 12px 0 -5px 0;
        height: 45px;
        border-radius: 4px;
        padding: 0 5px;
        border: 1px solid #e1e1e1;
      }
      .field.active{
        border-color: #7d2ae8;
      }
      .field i{
        width: 50px;
        font-size: 18px;
        text-align: center;
      }
      .field.active i{
        color: #7d2ae8;
      }
      .field input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 15px;
      }
      .field button{
        color: #fff;
        padding: 5px 18px;
        background: #7d2ae8;
      }
      .field button:hover{
        background: #8d39fa;
      }
      .btn_notifications {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1rem;
        height: 100%;
        cursor: pointer;
        .icon_bell {
          position: relative;
          .far {
            font-size: 1.5rem;
          }
          .bullet {
            background: var(--primary_color);
            position: absolute;
            color: #ffffff;
            top: -8px;
            right: -10px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 0.7rem;
            font-family: "is_medium";
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &:hover .icon_bell {
          color: var(--primary_color);
        }
      }
      .content__notif {
        min-width: 300px;
        padding: 0;
        border: 0;
        border-radius: 0.5rem;
        box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
        .content_box_notif {
          .notif_ttl {
            padding: 0.75rem;
            color: #efefef;
            border-radius: 0.5rem 0.5rem 0 0;
            line-height: 1;
            background: var(--primary_color);
            margin-bottom: 0.25rem;
          }
        }
        .item_notification {
          padding: 0.5rem 0.75rem;
          display: block;
          border-bottom: 1px solid $bg_grey;
          cursor: pointer;
          &:hover {
            background: #eeeeee;
          }
          &:last-child {
            border: 0;
          }
        }
        .notif_empty {
          text-align: center;
          display: flex;
          flex-direction: column;
          padding: 1rem 0.5rem;
          color: #dedede;
          .far {
            font-size: 3rem;
            margin-bottom: 0.25rem;
          }
          span {
            color: #cccccc;
          }
        }
      }
      .drop_select_pro {
        position: relative;
        .icodrop {
          position: absolute;
          top: 45%;
          right: -18px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #333333;
        }
      }
      .item_options {
        font-size: 1.25rem;
        cursor: pointer;
        &:hover {
          color: var(--primary_color);
        }
        .hydrated {
          font-size: 1.8rem;
        }
      }
      .content__profile_select{
        border: 0;
        border-radius: 0.5rem;
        box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
        padding: .25rem;
        div{
          padding: .25rem .5rem;
          border-radius: 5px;
          cursor: pointer;
          &:hover{
            background: #ededed;
          }
        }
      }
    }
  }
}
