.input-description-policy {
  min-height: 50px;
  max-height: 150px;
}

.img-fluid-policy{
  margin-right: 5px;
}

.delete_image_policy{
  cursor: pointer;
  //agrandar icono
  font-size: 1.5em;
}