@import "../../assets/theme/vars";

.wrapper_directory {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .wrp_filter_directory {
    max-width: 250px;
    width: 100%;
    text-align: right;
  }
  .divtop {
    .ttls {
      .box_tl {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .box_options_admin {
        position: relative;
        z-index: 1;
        padding-left: 1rem;
        background: #f2f2f2;
        .linkbn {
          cursor: pointer;
          background-color: #ffc907;
          min-width: 145px;
          display: block;
          width: 100%;
          text-align: center;
          font-size: 0.95rem;
          font-family: "is_medium";
          padding: 0.5rem 0.25rem;
          border-radius: 0.25rem;
        }
      }
    }
  }
  .form-control-sm {
    border: 1px solid #ced4da;
    &:focus-visible {
      border: 1px solid var(--primary_color);
      outline: 1px solid var(--primary_color);
    }
  }
  .box_ttl_opt {
    .wrp_filter_directory {
      max-width: 100%;
    }
  }



  .card-text {
    font-size: 0.9rem;
  }
  .alinks:hover {
    color: var(--primary_color);
    cursor: pointer;
  }
}

.box_filter_messg {
  background: cadetblue;
  margin-bottom: 2rem;
  gap: 10px;
  .box_ordering {
    width: 100%;
    margin: 0 !important;
  }
  .btn_clean_filter {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.15rem 0.5rem;
    border: 2px solid var(--primary_color);
    border-radius: 0.35rem;
    width: 55px;
    line-height: 1.1;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    &:hover {
      background: var(--primary_color);
      color: #fff;
    }
  }
  .form-control-sm {
    border: 1px solid #ced4da;
    width: 100%;
  }
}

.c_yellow {
  color: #ffc907;
}

.ttls_direct {
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .itemcat {
    background: var(--primary_color);
    color: #efefef;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
}

.h_line {
  font-size: 0.8rem;
  color: #bababa;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  span {
    position: relative;
    z-index: 1;
    padding: 0 1rem;
    background: #fff;
  }
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #dedede;
    position: absolute;
    top: 7px;
    left: 0;
  }
}

.name__categ {
  min-height: 50px;
}

.wrapper_details_directory {
  max-width: 720px;
  padding: 2rem 1rem;
  margin: 0 auto;
  .box_toolbar_back {
    margin-bottom: 1.25rem;
    line-height: 1;
    .go-back {
      cursor: pointer;
      background: #efefef;
      font-size: 0.9rem;
      padding: 0.45rem 0.75rem;
      border-radius: 0.5rem;
      &:hover {
        background: var(--primary_color);
        color: #fff;
      }
    }
  }
  .card {
    display: flex;
    .drawer_content {
      background-color: #fff;
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 20px;
      padding: 0.75rem 1rem;
      .conten_info {
        width: 100%;
        .ttls_direct {
          align-items: flex-start;
          // flex-direction: row;
          // gap: 10px;
          .itemcat {
            margin: 0;
            margin-bottom: 0.3rem;
          }
        }
        .box_data {
          margin-top: 2rem;
        }
      }
      .content_picture {
        width: 100%;
        max-width: 250px;
        text-align: center;
        img {
          max-height: 400px;
          border-radius: 1rem;
          // width: 100%;
        }
      }
    }
    .wrapper_ranking {
      padding: 0.75rem 1rem;
      display: flex;
      gap: 50px;
      .content_eval_ranking,
      .content_tooltip_ranking {
        width: 100%;
      }
      .set {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .ttls_direct {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        .itemcat {
          margin: 0 4px !important;
        }
      }

      .drawer_sets {
        width: 100%;
      }
      .ttls_direct {
        display: block;
        width: 100%;
      }
    }
  }
}

.item_data_details {
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 8px;
  margin-bottom: 0.5rem;
  .ico_box_detail {
    background: #efefef;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border-radius: 50%;
    font-size: 0.9rem;
  }
  .alinks:hover {
    color: var(--primary_color);
  }
}

@media screen and (max-width: 1200px) {
  .name__categ {
    min-height: 60px;
  }
}

@media screen and (max-width: 1090px) {
  .wrapper_directory {
    .box_filter_messg {
      flex-wrap: wrap;
      .box_ordering {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .wrapper_directory {
    .box_filter_messg {
      .box_ordering {
        width: 100% !important;
      }
    }
  }
}
